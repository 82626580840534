import './App.css';
import Routing from './routing/router';
import { defineLdsComponents } from '@elilillyco/ux-lds';
import '@elilillyco/ux-lds-react/src/css/index.css';
import { useEffect } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './auth/authconfig';
import { store } from './store/store';
import { Provider } from 'react-redux';

function App() {
  const msalInstance = new PublicClientApplication(msalConfig);
  useEffect(() => {
    if (
      window.location.hostname.includes('prod') || window.location.hostname === 'omt.lilly.com' || window.location.hostname.includes('qa') || window.location.hostname === 'd7lmv764yvhny.cloudfront.net'
    ) {
      // Override console methods only within this scope
      const originalConsole = { ...console }; // Create a copy of original console methods

      const noop = function () { }; // Define a no-operation function

      // Override console methods with noop
      ['log', 'debug', 'info', 'warn', 'error'].forEach(function (method) {
        console[method] = noop;
      });

      console.clear = noop;
      console.dir = noop;
      console.dirxml = noop;
      console.group = noop;
      console.groupCollapsed = noop;
      console.groupEnd = noop;
      console.table = noop;
      console.trace = noop;

      // Cleanup: Restore original console methods when component unmounts
      return () => {
        Object.keys(originalConsole).forEach(method => {
          console[method] = originalConsole[method];
        });
      };
    }
  }, []);
  useEffect(() => {
    /* Loads LDS web components into the window
      This is a dependency of ux-lds-react components
      This dependency may be removed as work on ux-lds-react progresses
    */
    defineLdsComponents();
  }, []);
  return (
    <>
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <Routing></Routing>
        </Provider>
      </MsalProvider>
    </>
  );
}

export default App;
