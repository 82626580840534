import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderNav from '../HeaderNav';
import "../../assets/components/OMMS/addmetadata.scss"

import {
    LdsModal,
    LdsTextField,
    useLdsModal,
    useToastContext,
    LdsLink,
    LdsIcon,
    LdsButton,
    LdsLoadingSpinner,
    LdsValidationError,
    LdsToast,
    LdsCard,
    LdsSelect,
    LdsTable
} from "@elilillyco/ux-lds-react";
import { addEditMetaDatavalue, getmetadata } from "../../store/Components/OMMS/addEditMetadata";

export default function AddEditMetadata() {

    const { isModalOpen, setIsModalOpen } = useLdsModal();
    const [edit, setEdit] = useState()
    const [input, setInput] = useState("")
    const [inputDSC, setInputDSC] = useState("")
    const [metaForAdd, setMetaForAdd] = useState("delivery_source")
    const [queryParam, setQueryParam] = useState("delivery_source")
    const [getValues, setValues] = useState()
    const [oldValue, setOldVlaue] = useState("")
    const { addToast } = useToastContext();
    const [ref, setRef] = useState(false)
    const [modalData, setModalData] = useState(false)
    const dispatch = useDispatch();
    const toastConfig = (errTxtMsg, msgType) => {
        return {
            toastMessage: errTxtMsg,
            actionText: "",
            actionCallback: () => { },
            variant: msgType,
            position: "top",
            align: "center",
            dismissible: true,
            light: false,
            timeout: 5000,
            inline: false,
            autoDismiss: true,
        }
    };
    const loader = useSelector(({ addMetaDataSlice }) => addMetaDataSlice.loader);

    useEffect(() => {
        dispatch(getmetadata(queryParam)).then((data) => { setValues(data.payload?.data.map((data) => Object.values(data))) })
    }, [queryParam, ref])

    const role = useSelector(({ user }) => user.role_ids);

    const currentUserData = role?.filter((roleData) => Object.keys(roleData)?.[0] === sessionStorage?.getItem('role'));
    const userID = (currentUserData[0] && Object.values(currentUserData?.[0]))

    const handleClick = (data) => {
        setOldVlaue(data)
        setInput("")
        setInputDSC("")
        setEdit(true)
        setIsModalOpen(true)

    }
    const addNew = () => {
        setInput("")
        setInputDSC("")
        setEdit(false)
        setIsModalOpen(true)
    }

    const payloadForEdit = {
        "metadata_values":
        {
            attribute_type: queryParam,
            old_value: oldValue?.[0],
            "new_value": input?.trim().replace(/\s+/g, ' '),
            ...(metaForAdd === 'delivery_source' && {
                delivery_source_code: inputDSC?.trim().replace(/\s+/g, ' '),
                delivery_source_code_old: oldValue?.[1]
            }),
            updated_by: userID?.[0],
        },
        "type":
            [
                "edit"
            ]
    }

    const payloadForAdd = {
        metadata: {
            attribute_type: metaForAdd,
            attribute_value: input?.trim().replace(/\s+/g, ' '),
            ...(metaForAdd === 'delivery_source' && {
                delivery_source_code: inputDSC?.trim().replace(/\s+/g, ' ')
            }),
            created_by: userID?.[0]
        },
        type: ["add"]
    };

    const handleSubmit = () => {
        // Check for special characters for certain metadata types
        const hasSpecial = input !== input?.replace(/[^a-zA-Z0-9-_ \/\\]/g, "");
        if (queryParam !== "supplier_name" && hasSpecial) {
            return addToast(toastConfig("Cannot use Special Char", "error"));
        }
    
        // Validate inputs before proceeding
        if (!input?.trim() || (input === oldValue?.[0])) {
            return addToast(toastConfig("Cannot submit same as previous or empty value", "error"));
        }
    
        // Edit Mode: Only validate if 'Delivery Source' is selected
        if (edit) {
            if (queryParam === 'delivery_source' && !inputDSC?.trim()) {
                return addToast(toastConfig("Delivery Source Code cannot be empty", "error"));
            }
        } 
        // Add Mode: Only validate if 'Delivery Source' is selected
        else {
            if (metaForAdd === 'delivery_source' && !inputDSC?.trim()) {
                return addToast(toastConfig("Delivery Source Code cannot be empty", "error"));
            }
        }
    
        // All checks passed, proceed with modal submission
        setModalData(true);
    };

    const handleDataSubmit = async () => {
        if (edit) {
            await dispatch(addEditMetaDatavalue(payloadForEdit))

            setTimeout(async () => {
                setModalData(false)
                setIsModalOpen(false)
                await dispatch(getmetadata(queryParam)).then((data) => { setValues(data.payload?.data.map((data) => Object.values(data))) })
            }, 2000);

        }
        else {
            await dispatch(addEditMetaDatavalue(payloadForAdd))
            setTimeout(async () => {
                setModalData(false)
                setIsModalOpen(false)
                await dispatch(getmetadata(queryParam)).then((data) => { setValues(data.payload?.data.map((data) => Object.values(data))) })
            }, 2000);

        }
        setInput();
        setInputDSC();
    }

    const staticObj = [{
        label: 'Delivery Source',
        value: "delivery_source"
    },
    {
        label: 'Supplier Name',
        value: "supplier_name"
    },
    {
        label: 'Platform',
        value: "platform"
    },
    {
        label: 'Contact Type',
        value: "contact_type"
    },
    {
        label: 'Delivery Source Type',
        value: "delivery_source_type"
    },
    {
        label: 'Delivery Channel',
        value: "delivery_channel"
    },
    {
        label: 'Engagement Classification',
        value: "engagement_classification"
    },
    {
        label: 'Metric Label',
        value: "metric_label"
    },
    {
        label: 'Hierarchy Classification',
        value: "hierarchy_classification"
    },
    {
        label: 'Metric Definition',
        value: "metric_definition"
    },
    {
        label: 'Ad Format',
        value: "ad_format"
    },
    {
        label: 'Engagement Depth',
        value: "engagement_depth"
    },
    {
        label: 'Contract Basis Classification',
        value: 'contract_basis_classification'
    },
    {
        label: 'Performance Basis Classification',
        value: 'performance_basis_classification'
    },
    {
        label: 'Engagement Rate Calculated Against ',
        value: "engagement_rate_calculated_against"
    },
    {
        label: 'Engagement Based Tactic',
        value: "engagement_based_tactic"
    }
    ]
    return (
        <>
            <HeaderNav msg={"Omnichannel Metadata Management System"} />
            <div className="mt-3 p-3">
                <LdsLink href="/omms">
                    <LdsIcon
                        description="CaretLeft"
                        label="search"
                        name="CaretLeft"
                        inline
                    />
                    Back to Overview
                </LdsLink>
            </div>
            <div className="container"><h2>Add/Edit Metadata</h2></div>
            <LdsCard className="col-9 mx-auto">

                <div className="d-flex mt-2 align-items-end justify-content-around">
                    <LdsSelect
                        errorMessage="This field is invalid"
                        id="mySelect"
                        className='col-3 mt-4'
                        label="Select Metadata"
                        name="mySelect"
                        onChange={(e) => { setQueryParam(e.value) }}
                        options={staticObj}
                        value={queryParam}
                    />

                    <LdsButton classes="system " onClick={addNew}>
                        Add New
                    </LdsButton>

                </div>
                <div className="mt-5 p-3 mx-auto">
                    {loader ? <div className="ta-center p-3 m-4">
                        <LdsLoadingSpinner size={50} />
                    </div> : <LdsTable>
                        {getValues ? <thead>
                            <tr>

                                <th scope="col">
                                    MetaData
                                </th>

                                <th scope="col">
                                    Edit
                                </th>
                            </tr>
                        </thead> : <div className="ta-center p-3 m-4">
                            <LdsLoadingSpinner size={50} />
                        </div>}
                        <tbody>
                            {getValues?.map((data, index) =>
                                <tr>
                                    <td className='table'>{data?.[0]}</td>
                                    <td ><LdsIcon style={{ display: 'inline' }} label='edit' onClick={() => { handleClick(data) }} className=" table-icons" name='PencilSimpleLineFill' /> </td>
                                </tr>
                            )}

                        </tbody>
                    </LdsTable>}


                </div>

            </LdsCard>

            <LdsModal
                modalId="testModal"
                open={isModalOpen}
                setModalOpen={setIsModalOpen}
                className="text-center"
                heading={edit ? "Edit Metadata" : "Add Metadata"}
            >
                <div>

                    <br />
                    {modalData === true ? <h3>Are you sure you want to update this values it will be reflected to all tactic screens</h3> :
                        <>
                            <label>Metadata</label>
                            <LdsSelect
                                id="exampleSelect"
                                // label="Metadata"
                                name="exampleSelect"
                                value={edit ? queryParam : metaForAdd}
                                options={staticObj}
                                onChange={(e) => (setMetaForAdd(e.value))}
                                disabled={edit ? true : false}
                                className="col-5 mx-auto"
                            />
                            <div className=" col-5 mx-auto">
                                {edit ?
                                    <div>
                                        <label style={{ color: "grey" }}>Previous Value</label>
                                        <LdsTextField
                                            id='orgName'
                                            name='orgName'
                                            disabled={true}
                                            value={oldValue?.[0]}
                                        />

                                        {queryParam === 'delivery_source' ? <>
                                            <label style={{ color: "grey" }}>Previous source code</label>

                                            <LdsTextField
                                                id='orgName'
                                                name='orgName'
                                                disabled={true}
                                                value={oldValue?.[1]}
                                            /> </> : ''}
                                    </div> : ""}
                                <div className="mt-4 ">
                                    <label>Enter New Metadata <span className="asterik-required">*</span></label>
                                    <LdsTextField
                                        required
                                        id='orgName'
                                        name='orgName'
                                        onChange={(e) => (setInput(e.target.value))}
                                        value={input}
                                    />
                                </div>
                                {(edit ? queryParam === 'delivery_source' : metaForAdd === 'delivery_source') && (
                                    <div className="mt-4">
                                        <label>Enter New Delivery Source Code <span className="asterik-required">*</span></label>
                                        <LdsTextField
                                            id='orgName'
                                            name='orgName'
                                            onChange={(e) => setInputDSC(e.target.value)}
                                            value={inputDSC}
                                        />
                                    </div>
                                )}


                            </div>
                        </>
                    }
                    <div className="mt-5 ">
                        <LdsButton classes="inverted" onClick={() => (setInput(""),
                            setInputDSC(), setIsModalOpen(false), setModalData(false), setMetaForAdd("delivery_source"))}>
                            Cancel
                        </LdsButton>
                        &nbsp; &nbsp;
                        <LdsButton onClick={modalData === true ? handleDataSubmit : handleSubmit}>
                            Submit
                        </LdsButton>
                    </div>
                </div>
                <LdsToast />
            </LdsModal >

        </>
    )
}
